var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('smart-table',{ref:"smartTable",attrs:{"headers":_vm.headers(),"search-function":_vm.searchFunction,"preference-key":"Account"},scopedSlots:_vm._u([(_vm.currentUserCanManageCRUD || _vm.currentUserCanManageAccount)?{key:"HEADER-ITEM-1",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"main","small":"","depressed":""},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.translations.newButton)+" ")],1)]},proxy:true}:null,{key:"results",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.name)+" "),(_vm.currentUserId === item.accountId)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-check")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.translations.itsme)+" ")]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.employmentStartDate ? _vm.momentDate(item.employmentStartDate) : item.employmentStartDate)+" ")]),_c('td',[_c('span',{style:(item.employmentEndDate < _vm.todaysDate ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.employmentEndDate ? _vm.momentDate(item.employmentEndDate) : item.employmentEndDate)+" ")])]),_c('td',[_vm._v(" "+_vm._s(item.keyCode)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.family)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.qualification)+" ")]),_c('td',{staticClass:"text-lowercase"},[(item.employeeId)?_c('span',{staticClass:"mr-1"},[(item.isActive)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"light-blue"}},[_vm._v(_vm._s(_vm.translations.employee))]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"small":"","color":"red"}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.translations.employee))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.translations.employeeNotActiveClockInExplain)+" ")])],1):_vm._e(),(item.capabilityCount)?_c('v-chip',{staticClass:"white--text mr-1",attrs:{"small":"","color":"orange"}},[_vm._v(" "+_vm._s(_vm.translations.userBackOffice)+" "+_vm._s(item.isAdmin === 0 ? '(' + item.capabilityCount + ')' : '(' + (item.capabilityCount - 1) + ')'))]):_vm._e(),(item.isAdmin)?_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":"main"}},[_vm._v(_vm._s(_vm.translations.admin))]):_vm._e()],1),_c('td',{attrs:{"width":"100"}},[_c('div',{staticClass:"d-flex justify-space-around"},[(item.loading)?_c('v-progress-circular',{attrs:{"size":15,"color":"main","indeterminate":""}}):_vm._e(),(!item.loading)?_c('v-icon',{staticClass:"cursor-pointer",attrs:{"small":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(" "+_vm._s((_vm.currentUserCanManageCRUD || _vm.currentUserCanManageAccount) ? 'mdi-square-edit-outline' : 'mdi-open-in-new')+" ")]):_vm._e(),((_vm.currentUserCanManageCRUD || _vm.currentUserCanManageAccount) && !item.loading)?_c('v-icon',{staticClass:"cursor-pointer",attrs:{"small":"","color":"error lighten-1"},on:{"click":function($event){return _vm.deleteConfirmation(item)}}},[_vm._v(" mdi-delete-outline ")]):_vm._e()],1)])]}}],null,true)}),_c('account-save-edit-dialog',{attrs:{"item":_vm.selectedItem,"show":_vm.showDialog,"update":_vm.isUpdate},on:{"closeDialog":function($event){return _vm.closeDialog()},"updated":function($event){return _vm.$refs.smartTable.getTableRows()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }